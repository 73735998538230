import { useContext, useEffect, useState } from "react";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import moment from "moment-timezone";
import { routes } from "src/Routes";
import { useNavigate } from "react-router-dom";
import { PaymentApi } from "src/api/payment.api";
import { OnBoardingApi } from "src/api/onboarding.api";
import { getClientCurrency } from "src/services/currency";
import { IClientCurrencyData } from "src/interfaces/currency";
import formatPackagePrice from "src/utils/helper/formatPackagePrice";
import { adjustPackagePriceByLessonFrequency } from "src/utils/helper/core";

export default function PaymentPlan({
  setInvalidLink,
  onBoarding,
  order,
  bookLessons,
  setOrder,
}: any) {
  const {
    state: {
      onBoarding: { learningPackage },
      subject,
      lessonFrequency,
    },
  } = useContext(DirectPayContext);
  const [{ user, clientIpCountry }] = useStateValue();
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [currency, setCurrency] = useState<IClientCurrencyData | null>(null);
  useEffect(() => {
    if (clientIpCountry) {
      let cp = getClientCurrency(clientIpCountry);
      switch (cp.currencyCode) {
        case "USD":
        case "CAD":
        case "GBP":
          setCurrency(cp);
          break;
        default:
          setCurrency({
            symbol: "$",
            fromNairaValue: 0.0024,
            fromPoundValue: 1.34,
            fromDollarValue: 1,
            currencyCode: "USD",
          });
      }
    }
  }, [clientIpCountry]);

  if (!learningPackage) {
    setInvalidLink(true);
  }

  function capitalizeFirstCharacter(str: string) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }

  const applyCoupon = async () => {
    setCouponError("");
    const response = await PaymentApi.postApplyDiscountCode(
      couponCode,
      order?.subscriptionId
    );
    if (response?.data?.error) {
      setCouponError("Invalid coupon code");
    } else if (response?.data?.payment?.state === "COMPLETE") {
      setCouponSuccess("Coupon code applied successfully");
      if (
        onBoarding!.onboarding?.leadFunnel === "direct-to-pay-flow-1" ||
        onBoarding!.onboarding?.leadFunnel === "direct-to-pay-flow-3"
      ) {
        await bookLessons(order?.subscriptionId, onBoarding);
        navigate(routes.CHECKOUT_PAYMENT_CONFIRMATION.url, {
          state: {
            order,
          },
        });
      } else {
        await OnBoardingApi.updateOnBoardingDetails(
          { paymentStatus: "COMPLETE" },
          onBoarding!.onboarding?._id
        );
        navigate(routes.CHECKOUT_PAYMENT_CONFIRMATION.url, {
          state: {
            order,
          },
        });
      }
    }
  };

  // handle textfield entry for coupon code
  const handleCouponCode = (e: any) => {
    setCouponCode(e.target.value);
  };

  const utcDateString = onBoarding?.lessonSchedule?.utc;
  const localDate = moment.utc(utcDateString).local();
  const formattedDate = localDate.format("dddd MMMM Do YYYY, hh:mm A");

  return (
    <div className="white-card-rounded p-4 flex-1 ">
      {/* customer name */}
      <p className="text-gray-purple text-sm">Customer Name</p>
      <h1 className="font-medium text-lg mt-2">
        {user?.firstname} {user?.lastname}
      </h1>
      <div className="border  border-gray-100 my-3"></div>
      {/* package type */}
      <h1 className="text-gray-purple text-sm">Lesson Details</h1>
      {onBoarding?.selectedTutor?.firstName ? (
        <h1 className="font-medium text-lg mt-2">{`${capitalizeFirstCharacter(
          onBoarding?.onboarding?.subject
        )} sessions with  ${
          onBoarding?.selectedTutor?.firstName
        } ${onBoarding?.selectedTutor?.lastName?.charAt(0)}`}</h1>
      ) : (
        <h1 className="font-medium text-lg mt-2">
          {capitalizeFirstCharacter(onBoarding?.onboarding?.subject || subject)}
        </h1>
      )}
      <h1 className="font-medium text-sm">
        {learningPackage?.type},{" "}
        {learningPackage?.numberOfLessons &&
          learningPackage?.numberOfLessons *
            (learningPackage?.type === "Monthly Package"
              ? lessonFrequency
              : 1)}{" "}
        Lessons
      </h1>
      {onBoarding?.lessonSchedule?.utc && (
        <h1 className="font-medium text-sm">{`First Lesson: ${formattedDate}`}</h1>
      )}
      <div className="border  border-gray-100 my-3"></div>

      <div>
        <h1 className="text-gray-purple text-sm">Payment plan</h1>
        <div className="flex justify-between items-center gap-4 mt-2 flex-wrap">
          <p className="font-semibold text-right text-lg">
            {learningPackage?.perLessonRate}
            /Session
          </p>
        </div>
      </div>
      <div className="border  border-gray-100 my-3"></div>
      {/* discount code */}
      <div>
        <h1 className="text-gray-purple text-sm">Coupon Code</h1>
        <div className="flex justify-between items-center gap-4 mt-2 flex-wrap">
          {/* Text field for code add function to update code*/}
          <input
            type="text"
            placeholder="Enter code"
            className="w-1/2 border border-gray-200 rounded-md px-4 py-1"
            onChange={handleCouponCode}
            data-test="d2pCouponCodeInput"
          />
          <button
            className="text-xs text-white bg-primary rounded-md px-4 py-1"
            onClick={applyCoupon}
            data-test="d2pCouponCodeButton"
          >
            Apply Code
          </button>
        </div>
        {couponError && (
          <p className="text-red-500 text-xs mt-2">{couponError}</p>
        )}
        {couponSuccess && (
          <p className="text-green-500 text-xs mt-2">{couponSuccess}</p>
        )}
      </div>
      <div className="border  border-gray-100 my-3"></div>
      <div className="flex justify-between items-center text-xl font-bold">
        <h1>Total</h1>
        <p>
          {formatPackagePrice(
            currency?.currencyCode || "USD",
            adjustPackagePriceByLessonFrequency(
              lessonFrequency,
              learningPackage?.transactionAmount,
              learningPackage?.type
            )
            // learningPackage?.transactionAmount &&
            //   learningPackage?.transactionAmount *
            //     (learningPackage?.type === "Monthly Package"
            //       ? lessonFrequency
            //       : 1)
          )}
        </p>
      </div>
    </div>
  );
}
