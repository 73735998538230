import TrustpilotBadge from "src/components/Shared/TrustpilotBadge";
import { useTrustpilot } from "src/context/TrustpilotContext";

const ChoosePackage = ({ request }: { request: string | null | undefined }) => {
  const { isScriptLoaded } = useTrustpilot();

  console.log(isScriptLoaded, "is new sciprt");
  return (
    <>
      <section className="text-center z-10 container md:mt-6 px-8 mt-4 left-8 right-8 ">
        <h1 className="font-bold md:text-2xl text-2xl leading-10 max-w-3xl mx-auto ">
          {request === "old"
            ? "Here are other payment plans that might meet your needs better."
            : "Choose a package below to get started."}
        </h1>

        {isScriptLoaded && (
          <div className="mt-7">
            <TrustpilotBadge />
          </div>
        )}
      </section>
    </>
  );
};

export default ChoosePackage;
