export const adjustPackagePriceByLessonFrequency = (
  lessonFrequency: number,
  learningPackageAmount?: number,
  learningPackageType?: string
) => {
  if (!learningPackageAmount) return;
  return learningPackageType === "Monthly Package"
    ? learningPackageAmount * lessonFrequency
    : learningPackageAmount;
};
