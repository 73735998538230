import axios, { AxiosResponse } from "axios";

export async function getInvoice(id: string): Promise<AxiosResponse> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/booking/prep-invoice`,
      {
        params: {
          id,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("caught error: ", error);
    throw error;
  }
}
