import { useEffect, useState, useContext } from "react";

import { Context as DirectPayContext } from "src/context/DirectPayContext";
import PaymentPlanCard from "./PaymentCard";
import { FaChevronLeft } from "react-icons/fa";
import moment from "moment-timezone";
import { LessonSchedule } from "src/interfaces/direct-pay";
import { PaymentApi } from "src/api/payment.api";
import { useStateValue } from "src/context/StateProvider";
import { getClientCurrency } from "src/services/currency";
import { IClientCurrencyData } from "src/interfaces/currency";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "src/Routes";
import LoadingScreen from "src/components/LottieAnimations/SpinningRose";
import { toast } from "react-toastify";
import { StudentApi } from "src/api/student.api";
import { updateLessonSchedulesForDpMoreLessons } from "src/utils/dateUtils";
import { sortLp } from "src/utils/helper/sortLearningPackage";
import { TrustpilotProvider } from "src/context/TrustpilotContext";

import ChoosePackage from "./ChoosePackage";

type PaymentPageProp = {
  setisPayment?: React.Dispatch<React.SetStateAction<boolean>>;
  proficiencyLevel?: string;
  request?: "old" | "new" | null;
};

export interface IPaymentPlans {
  halfLesson?: IPackageType[];
  fullLesson?: IPackageType[];
}

export const lessonDurationOptions = [
  { value: 1, label: "55 minutes", default: false },
  { value: 0.5, label: "25 minutes", default: false },
];

export type IPackageType = {
  _id: string;
  type: string;
  perLessonRateUSD: string;
  perLessonRateCAD: string;
  perLessonRateGBP: string;
  plan: string;
  discount: number;
  transactionAmountUSD: number;
  transactionAmountGBP: number;
  transactionAmountCAD: number;
  numberOfLessons: number;
  lessonType: string;
};

export default function PaymentPlan({
  setisPayment,
  proficiencyLevel,
  request,
}: PaymentPageProp) {
  const {
    state: {
      lessonSchedule,
      onBoarding,
      isLoading,
      lessonFrequency,
      isBookingMoreLessons,
    },
    actions: { updateOnboarding, setLessonSchedule, setLessonFrequency },
  } = useContext(DirectPayContext);
  const [{ activeSubscription }] = useStateValue();
  const [{ clientIpCountry, user }] = useStateValue();
  const [result, setResult] = useState<IPackageType[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<IPackageType>();
  const [recurringDates, setRecurringDates] = useState<LessonSchedule[]>([]);
  const [active, setActive] = useState("full");
  const [paymentPackages, setPaymentPackages] = useState<any>(null);
  const [currency, setCurrency] = useState<IClientCurrencyData | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (clientIpCountry) {
      setCurrency(getClientCurrency(clientIpCountry));
    }
  }, [clientIpCountry]);
  const handleToggle = () => {
    setActive((prev) => (prev === "full" ? "half" : "full"));
  };

  useEffect(() => {
    const getPackages = async () => {
      const response = await PaymentApi.getLearningPackages();
      setPaymentPackages(response.data);
    };
    getPackages();
  }, []);

  useEffect(() => {
    switch (proficiencyLevel) {
      case "Intermediate or Above":
        let intermediate =
          active === "full"
            ? paymentPackages?.intermediate["fullLesson"]
            : paymentPackages?.intermediate["halfLesson"];
        setResult(intermediate?.sort(sortLp));
        break;

      default:
        let beginner =
          active === "full"
            ? paymentPackages?.beginner["fullLesson"]
            : paymentPackages?.beginner["halfLesson"];
        setResult(beginner?.sort(sortLp));
        break;
    }
  }, [active, lessonFrequency, paymentPackages, proficiencyLevel]);

  //function to keep date even with daylight savings
  const incrementDateWithSameLocalTime = (
    startDate: string | Date,
    numberOfWeeks: number,
    timezone: string
  ) => {
    let initialDate = moment.tz(startDate, timezone);
    let initialHour = initialDate.hour();

    // Create a new date by adding weeks to the initial date
    let newDate = moment.tz(startDate, timezone).add(numberOfWeeks, "weeks");

    // Set the hour of the new date to match the initial hour, preserving local time
    newDate.hour(initialHour);

    return new Date(newDate.format());
  };
  const selectPaymentBundle = async (lessonPackage: IPackageType) => {
    setRecurringDates((prevState) => []);
    setSelectedPlan(lessonPackage);
    let newSchedule: any = [...lessonSchedule];
    let frequencyToUse = activeSubscription?.lessonFrequency || lessonFrequency;
    if (isBookingMoreLessons) {
      let subFrequency = activeSubscription?.lessonFrequency || lessonFrequency;
      let subSchedule = activeSubscription?.lessonSchedule || lessonSchedule;
      frequencyToUse = subFrequency;
      newSchedule = Array(subFrequency).fill(null);
      const mostRecentLesson = await StudentApi.getMostRecentLesson(user?._id);
      newSchedule = updateLessonSchedulesForDpMoreLessons(
        subSchedule,
        mostRecentLesson[0]
      );

      setLessonSchedule(newSchedule);
      setLessonFrequency(subFrequency);
    }
    // return;
    newSchedule = newSchedule.map((schedule: any) => {
      return {
        ...schedule,
        duration:
          active === "full"
            ? { ...lessonDurationOptions[0] }
            : { ...lessonDurationOptions[1] },
      };
    });
    setLessonSchedule(newSchedule);

    let rec: any = [...recurringDates];
    let noOfWeeks: number =
      Number(lessonPackage.numberOfLessons) / Number(frequencyToUse) - 1;
    let noOfWeeksRem =
      Number(lessonPackage.numberOfLessons) % Number(frequencyToUse);

    newSchedule.forEach((schedule: any) => {
      if (lessonPackage.type.toLowerCase() === "monthly package") {
        for (let i = 0; i <= 3; i++) {
          const newRecurringDate = {
            date: incrementDateWithSameLocalTime(
              schedule.date || new Date(),
              i,
              schedule.timeZone || ""
            ),
            duration: schedule.duration,
            default: false,
            time: schedule.date,
            timeZone: schedule.timeZone,
            utc: incrementDateWithSameLocalTime(
              schedule.utc || new Date(),
              i,
              schedule.timeZone || ""
            ),
            fullLessonDate: incrementDateWithSameLocalTime(
              schedule.utc || new Date(),
              i,
              schedule.timeZone || ""
            ),
          };
          setRecurringDates((prevState) => [...prevState, newRecurringDate]);
          rec = [...rec, newRecurringDate];
        }
      } else {
        let remWeek = 0;
        for (let i = 0; i <= noOfWeeks; i++) {
          const newRecurringDate = {
            date: incrementDateWithSameLocalTime(
              schedule.date || new Date(),
              i,
              schedule.timeZone || ""
            ),
            duration: schedule.duration,
            default: false,
            time: schedule.date,
            timeZone: schedule.timeZone,
            utc: incrementDateWithSameLocalTime(
              schedule.utc || new Date(),
              i,
              schedule.timeZone || ""
            ),
            fullLessonDate: incrementDateWithSameLocalTime(
              schedule.utc || new Date(),
              i,
              schedule.timeZone || ""
            ),
          };
          setRecurringDates((prevState) => [...prevState, newRecurringDate]);
          rec = [...rec, newRecurringDate];
          remWeek = i + 1;
        }
        if (noOfWeeksRem > 0) {
          const newRecurringDate = {
            date: incrementDateWithSameLocalTime(
              schedule.date || new Date(),
              remWeek,
              schedule.timeZone || ""
            ),
            duration: schedule.duration,
            default: false,
            time: schedule.date,
            timeZone: schedule.timeZone,
            utc: incrementDateWithSameLocalTime(
              schedule.utc || new Date(),
              remWeek,
              schedule.timeZone || ""
            ),
            fullLessonDate: incrementDateWithSameLocalTime(
              schedule.utc || new Date(),
              remWeek,
              schedule.timeZone || ""
            ),
          };
          setRecurringDates((prevState) => [...prevState, newRecurringDate]);
          rec = [...rec, newRecurringDate];
          noOfWeeksRem -= 1;
        }
      }
    });

    // return;
    try {
      await updateOnboarding(
        { selectedLessonSchedule: [...rec] },
        onBoarding.onboarding!._id
      );

      await updateOnboarding(
        {
          learningPackage: {
            discount: lessonPackage.discount,
            lessonType: lessonPackage?.lessonType,
            type: lessonPackage.type,
            currencyCode: !["USD", "GBP", "CAD"].includes(
              currency!.currencyCode
            )
              ? "USD"
              : currency!.currencyCode,
            perLessonRate:
              currency?.currencyCode === "USD"
                ? lessonPackage.perLessonRateUSD
                : currency?.currencyCode === "GBP"
                ? lessonPackage.perLessonRateGBP
                : currency?.currencyCode === "CAD"
                ? lessonPackage.perLessonRateCAD
                : lessonPackage.perLessonRateUSD,
            plan: lessonPackage.plan,
            numberOfLessons: lessonPackage.numberOfLessons,
            transactionAmount:
              currency?.currencyCode === "USD"
                ? lessonPackage.transactionAmountUSD
                : currency?.currencyCode === "GBP"
                ? lessonPackage.transactionAmountGBP
                : currency?.currencyCode === "CAD"
                ? lessonPackage.transactionAmountCAD
                : lessonPackage.transactionAmountUSD,
          },
        },
        onBoarding.onboarding!._id
      );
      navigate(
        `${routes.CHECKOUT.url}?learningPackage=${lessonPackage._id}&userId=${onBoarding.onboarding?.userId}`
      );
    } catch (error) {
      toast.error("An error occured", {
        position: "bottom-right",
      });
    }
  };

  return (
    <TrustpilotProvider>
      <div>
        {!paymentPackages || !result || isLoading ? (
          <LoadingScreen />
        ) : (
          <div className="text-primary relative">
            {setisPayment && (
              <div className="container">
                <button
                  onClick={() => setisPayment(false)}
                  className="gray-button-2 flex items-center space-x-2 mt-4"
                >
                  <FaChevronLeft className="w-4 h-4" />
                  {/* <span className="hidden lg:block">Back to Tutor</span> */}
                </button>
              </div>
            )}
            <ChoosePackage request={request} />

            <div className="container mt-20 px-3 md:px-8 pb-12 left-8 right-8 ">
              <div className="flex justify-center">
                <div>
                  <div className="flex gap-1 justify-center md:justify-start md:gap-6 mx-auto pb-8">
                    <div className="flex gap-2 items-center cursor-pointer">
                      <p
                        className={`
                    ${
                      active === "half" ? "text-secondary" : "text-primary"
                    } font-semibold text-lg md:font-bold md:text-xl  transition-transform duration-300 ease-in-out`}
                      >
                        25 minutes
                      </p>
                    </div>
                    <div className="flex items-center justify-center space-x-2">
                      <div
                        className={`${
                          active === "full" ? "bg-secondary" : "bg-secondary"
                        } relative w-[59px] h-7 md:h-8 rounded-full transition-all duration-300 py-[2px]`}
                        onClick={handleToggle}
                      >
                        <div
                          className={`${
                            active === "full"
                              ? "translate-x-full"
                              : "translate-x-0"
                          } absolute left-0 w-1/2 h-[24px] md:h-[28px] bg-white rounded-full transition-transform duration-300 ease-in-out`}
                        ></div>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center cursor-pointer">
                      <p
                        className={`
                    ${
                      active === "full" ? "text-secondary" : "text-primary"
                    } font-semibold text-lg md:font-bold md:text-xl  transition-transform duration-300 ease-in-out`}
                      >
                        55 minutes
                      </p>
                    </div>
                  </div>

                  <div
                    className={`items-start grid grid-cols-1 sm:grid-cols-2 ${
                      !pathname.includes("dashboard") && "lg:grid-cols-4"
                    } xl:grid-cols-4  gap-7  md:gap-5  `}
                  >
                    {result?.map((payment: any, index: any) => (
                      <PaymentPlanCard
                        paymentPlans={payment}
                        selectPaymentBundle={selectPaymentBundle}
                        selected={selectedPlan?.type === payment.type}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </TrustpilotProvider>
  );
}
