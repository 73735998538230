import RevolutCheckout, { RevolutCheckoutInstance } from "@revolut/checkout";

import Lottie from "lottie-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentApi } from "src/api/payment.api";
import * as loadingAnimation from "src/assets/animations/rose-loader.json";
import InvoicePayment from "src/components/Checkout/InvoicePayment";
import { Instance } from "src/interfaces/payment";
import Header from "src/layouts/DashboardHeader";
import { routes } from "src/Routes";

import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import ReactModal from "react-modal";
import { LessonApi } from "src/api/lesson.api";
import { OnBoardingApi } from "src/api/onboarding.api";
import CardInfo from "src/components/Checkout/CardInfo";
import InvalidPaymentLink from "src/components/Checkout/InvalidPaymentLink";
import PaymentMade from "src/components/Checkout/PaymentMade";
import PaymentPlan from "src/components/Checkout/PaymentPlan";
import PreppingDashboard from "src/components/LottieAnimations/PreppingDashboard";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import { getClientCurrency } from "src/services/currency";
import initStripe from "src/utils/stripeUtils";
import Swal from "sweetalert2";
import { InvoiceApi } from "src/api/invoice.api";
import { getLearningPackage } from "src/server/endpoints/payment";
const logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/logo.webp`;

const Revolut = () => {
  const stripe = useStripe();
  const [{ user }] = useStateValue();
  const elements = useElements();
  const navigate = useNavigate();
  // Use the useLocation hook to get the location object.
  const location = useLocation();
  const route = useMemo(() => new URL(window.location.href), []);
  const {
    state: {
      onBoarding,
      isLoading,
      lessonFrequency,
      lessonSchedule,
      isBookingMoreLessons,
    },
    actions: {
      bookLessons,
      setLessonFrequency,
      setLessonSchedule,
      getOnboarding,
    },
  } = useContext(DirectPayContext);
  const [invoice, setInvoice] = useState<any>(null);

  // Parse the query parameters from the location object.
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  // Get the value of the 'invoiceId' parameter.
  // Get the value of the 'paymentPlan' parameter.
  const invoiceId = useMemo(() => {
    if (invoice) {
      return null;
    }

    return searchParams.get("invoiceId");
  }, [searchParams, invoice]);
  const learningPackage = useMemo(() => {
    if (invoice) {
      return invoice?.learningPackageId;
    }

    return searchParams.get("learningPackage");
  }, [invoice, searchParams]);
  const [{ clientIpCountry }] = useStateValue();

  const currency = useMemo(() => {
    let currency = getClientCurrency(clientIpCountry);
    return !["USD", "GBP", "CAD"].includes(currency!.currencyCode)
      ? "USD"
      : currency!.currencyCode;
  }, [clientIpCountry]);

  const userId = useMemo(() => {
    if (invoice) {
      return invoice?.customerId;
    }

    return searchParams.get("userId");
  }, [searchParams, invoice]);
  const [loading, setLoading] = useState(true);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);
  const [order, setOrder] = useState<any>(null);
  const [learningPackageDoc, setLearningPackageDoc] = useState<any>(null);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [stripeModal, setStripeModal] = useState({
    open: false,
    success: false,
  });
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [isBookingLessonsForInvoice, setIsBookingLessonsForInvoice] =
    useState(false);
  console.log(invoice, order);
  const getInvoice = async () => {
    if (invoiceId) {
      try {
        const invoiceRes = await InvoiceApi.getInvoice(invoiceId);
        setInvoice(invoiceRes?.data);
        setLessonFrequency(invoiceRes?.data?.lessonFrequency);
        const schedule = Array.from({
          length: invoiceRes?.data?.lessonFrequency,
        }).map((_, index) => ({
          date: invoiceRes?.data?.subjects?.[0]?.schedule?.[index]?.date,
        }));
        setLessonSchedule(schedule);
        // await getOnboarding(invoiceRes?.data?.customerId);
        // const lpRes = await getLearningPackage(
        //   invoiceRes?.data?.learningPackageId
        // );
        // setLearningPackageDoc(lpRes?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const createRevolutOrder = useCallback(async () => {
    try {
      if (invoiceId || learningPackage) {
        let response;
        if (invoiceId) {
          response = await PaymentApi.createRevolutOrder({ invoiceId });
          setOrder(response.data);
        } else if (learningPackage) {
          response = await PaymentApi.createSubscription({
            learningPackageId: learningPackage,
            userId: userId,
            lessonFrequency,
            lessonSchedule,
            currency,
          });
          return Array.isArray(response.data?.payments)
            ? response.data?.payments[0]
            : response.data?.payments;
        }
        console.log("order created");
        return 1;
      } else {
        setInvalidLink(true);

        return 0;
      }
    } catch (error) {
      setInvalidLink(true);

      console.log("caught error: ", error);
      return 0;
    }
  }, [
    currency,
    invoiceId,
    learningPackage,
    lessonFrequency,
    lessonSchedule,
    userId,
  ]);

  const getPaymentOrder = useCallback(async () => {
    try {
      if (invoiceId) {
        let response;
        if (invoiceId) {
          response = await PaymentApi.getPaymentOrder({ invoiceId });
        }
        console.log("order fetched");
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log("caught error: ", error);
      return [];
    }
  }, [invoiceId]);

  const updateRevolutOrder = useCallback(
    async (state: "PENDING" | "FAILED" | "COMPLETE") => {
      try {
        const response = await PaymentApi.updateRevolutOrder({
          paymentId: order?._id,
          reference: invoiceId || learningPackage,
          state,
        });
        if (learningPackage && userId) {
          if (
            onBoarding!.onboarding?.leadFunnel === "direct-to-pay-flow-1" ||
            onBoarding!.onboarding?.leadFunnel === "direct-to-pay-flow-3" ||
            isBookingMoreLessons
          ) {
            if (response?.data?.state === "COMPLETE") {
              await bookLessons(order?.subscriptionId, onBoarding);
            }
          } else {
            await OnBoardingApi.updateOnBoardingDetails(
              { paymentStatus: "COMPLETE" },
              onBoarding!.onboarding?._id
            );
          }
        }
        if (invoice) {
          if (response?.data?.state === "COMPLETE") {
            setIsBookingLessonsForInvoice(true);
            await LessonApi.autoBookLesson({
              invoiceId: invoice._id,
              subscriptionId: order?.subscriptionId,
            }).catch((error) => {
              Swal.fire({
                color: "#341A64",
                title: `<strong>Thanks for your payment</strong>`,
                html: `<p>Your lessons will be booked shortly.</p><p>Feel free to <a href="mailto:info@topset.app" style="text-decoration: underline;" >reach out to us</a> for any further assistance</p>`,
                confirmButtonText: `OK`,
                confirmButtonColor: "#D33479",
              });
            });
            setIsBookingLessonsForInvoice(false);
          }
        }
        // setOrder(response.data);
        console.log("order updated");
      } catch (error) {
        console.log("caught error: ", error);
      }
    },
    [
      order,
      invoiceId,
      learningPackage,
      userId,
      onBoarding,
      isBookingMoreLessons,
      bookLessons,
      invoice,
    ]
  );

  const handleRevolutPaymentRequest = useCallback(() => {
    RevolutCheckout(
      order.publicId || order.token,
      `${
        route.host === "learn.topset.app" || route.host === "topset.app"
          ? "prod"
          : "sandbox"
      }`
    ).then((instance: Instance | RevolutCheckoutInstance) => {
      const paymentRequest = instance.paymentRequest({
        target: document.getElementById("revolut-payment-request")!,

        async onSuccess() {
          setLoading(true);
          await updateRevolutOrder("COMPLETE");
          if (invoice) {
            navigate(routes.CHECKOUT_THANK_YOU.url, {
              state: {
                order,
              },
            });
          } else if (learningPackage) {
            navigate(routes.CHECKOUT_PAYMENT_CONFIRMATION.url, {
              state: {
                order,
              },
            });
          }
        },
        savePaymentMethodFor: "merchant",
        onError(error) {
          console.log(`Error: ${error.message}`);
          // if (order.state !== "COMPLETE") updateRevolutOrder("FAILED");
        },
        buttonStyle: { size: "small", variant: "light" },
      });
      paymentRequest.canMakePayment().then((method: any) => {
        setLoading(false);
        if (method) {
          paymentRequest.render();
        } else {
          console.log("Not supported");
          paymentRequest.destroy();
        }
      });
    });
  }, [
    order,
    route.host,
    updateRevolutOrder,
    invoiceId,
    learningPackage,
    navigate,
  ]);

  useEffect(() => {
    //call endpoint to initiate order creation
    const init = async () => {
      if (searchParams.get("stripe") === "success") {
        await updateRevolutOrder("COMPLETE");
        navigate(
          `${routes.CHECKOUT_THANK_YOU.url}?amount=${searchParams.get(
            "amount"
          )}&currency=${searchParams.get("currency")}`
        );
        return;
      }

      if (invoiceId) {
        const payments = await getPaymentOrder();
        if (payments.length === 0) {
          await createRevolutOrder();
        } else if (payments.length >= 1) {
          if (payments[0].state === "COMPLETE") {
            setPaymentComplete(true);
          } else {
            setOrder(payments[0]);
          }
        }
        await getInvoice();
      } else if (learningPackage && userId) {
        const payment = await createRevolutOrder();

        if (user && Object.keys(onBoarding).length === 0) {
          return navigate(routes.LOGIN.url);
        }

        if (payment.state === "COMPLETE") {
          setPaymentComplete(true);
        } else {
          setOrder({ ...payment, invoice });
        }
        setLoading(false);
      } else {
        setInvalidLink(true);
      }
    };

    init();
  }, []);

  useEffect(() => {
    console.log("russ", "-----", order);
    if (stripe && order != null) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: order?.amount?.currency.toLowerCase(),
        total: {
          label: "Total",
          amount: order?.amount?.value ?? 10,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      console.log("-----", pr);

      pr.canMakePayment().then((result: any) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on("paymentmethod", async (ev: any) => {
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          process.env.REACT_APP_STRIPE_CLIENT_SECRET as string,
          {
            payment_method: ev.paymentMethod.id,
          },
          {
            handleActions: false,
          }
        );

        if (error) {
          console.log("-----", error.message);
          ev.complete("fail");
        } else {
          ev.complete("success");
          if (paymentIntent.status === "requires_action") {
            stripe.confirmCardPayment(
              process.env.REACT_APP_STRIPE_CLIENT_SECRET as string
            );
          }
        }
      });
    }
  }, [stripe, order]);

  useEffect(() => {
    if (order) {
      handleRevolutPaymentRequest();
    }
  }, [order, handleRevolutPaymentRequest]);

  async function onPaymentSuccess() {
    await updateRevolutOrder("COMPLETE");

    if (invoice) {
      navigate(
        `${routes.CHECKOUT_THANK_YOU.url}?amount=${
          order?.amount?.value as string
        }&currency=${order?.amount?.currency}`
      );
    } else if (learningPackage) {
      navigate(
        `${routes.CHECKOUT_PAYMENT_CONFIRMATION.url}?amount=${
          order?.amount?.value as string
        }&currency=${order?.amount?.currency}`
      );
    }
  }

  const handleRevolutPopup = () => {
    switch (order?.gateway.name) {
      case "STRIPE":
        // stripeCheckout();
        setStripeModal((p) => ({ ...p, open: true }));

        break;

      default:
        RevolutCheckout(
          order.publicId || order.token,
          `${
            route.host === "learn.topset.app" || route.host === "topset.app"
              ? "prod"
              : "sandbox"
          }`
        ).then((instance: Instance | RevolutCheckoutInstance) => {
          setTimeout(() => {
            setIframeLoading(false);
          }, 2000);
          // work with instance
          instance.payWithPopup({
            async onSuccess() {
              setLoading(true);
              await updateRevolutOrder("COMPLETE");
              if (invoice) {
                navigate(
                  `${routes.CHECKOUT_THANK_YOU.url}?amount=${
                    order?.amount?.value as string
                  }&currency=${order?.amount?.currency}`
                );
              } else if (learningPackage) {
                navigate(
                  `${routes.CHECKOUT_PAYMENT_CONFIRMATION.url}?amount=${
                    order?.amount?.value as string
                  }&currency=${order?.amount?.currency}`
                );
              }
            },
            savePaymentMethodFor: "merchant",
            onError(message) {
              if (order.state !== "COMPLETE") updateRevolutOrder("FAILED");
            },
            onCancel() {
              instance.destroy();
            },
          });
        });
    }
  };

  async function stripeCheckout() {
    const stripe = await initStripe();
    if (stripe) {
      stripe
        .redirectToCheckout({
          // lineItems: [
          // 	{
          // 		price: order?.stripeOrderId,
          // 		quantity: 1,
          // 	},
          // ],
          // mode: "payment",
          // successUrl: `${
          // 	process.env.REACT_APP_CURRENT_DOMAIN
          // }/checkout?stripe=success&amount=${
          // 	order?.amount?.value as string
          // }&currency=${order?.amount?.currency}`,
          // cancelUrl: `${process.env.REACT_APP_CURRENT_DOMAIN}/checkout?stripe=failed`,
          // customerEmail: order?.invoice?.email,

          sessionId: order.gateway.sessionId,
        })
        .then((result) => {
          if (result.error) {
            // Handle error here
            console.error(result.error.message);
          } else {
            // Trigger your function here
            // onCheckoutSuccess();
          }
        });
    }
  }

  if (isLoading) {
    return <PreppingDashboard />;
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: `${order?.invoice?.firstname} ${order?.invoice?.lastname}`,
          },
        })
        .then(async function (result) {
          setStripeModal((p) => ({ ...p, success: true }));
          console.log(result);
          await onPaymentSuccess();
          return result;
        })
        .finally(() => {
          setTimeout(
            () => setStripeModal((p) => ({ ...p, open: false })),
            1000
          );
          // setStripeModal((p) => ({ ...p, open: false }));
        });
      // Rest of the code...
      if (error) {
        console.error(error);
      } else {
        // Confirm payment with the client secret
        const { clientSecret } = await fetch("/create-payment-intent").then(
          (res) => res.json()
        );

        const { error: confirmError, paymentIntent } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
          });

        if (confirmError) {
          console.error(confirmError);
        } else {
          console.log("Payment successful:", paymentIntent);
        }
      }
    } else {
      // Handle the case when cardElement is null.
    }
  };

  const cardElementOptions = {
    // style: {
    // 	base: {
    // 		fontSize: "16px",
    // 		color: "#32325d",
    // 		"::placeholder": {
    // 			color: "#aab7c4",
    // 		},
    // 	},
    // 	invalid: {
    // 		color: "#fa755a",
    // 		iconColor: "#fa755a",
    // 	},
    // },
    hidePostalCode: true,
  };

  return (
    <>
      <div className="text-primary h-screen">
        <div className="hidden sm:block relative  ">
          <Header />
        </div>

        <Helmet>
          <title>TopSet | Checkout</title>
          <meta name="description" content="Online tutoring" />
          <meta name="keywords" content="Online tutoring" />
        </Helmet>

        {/* <SuspenseLoader /> */}
        {loading && (
          <Lottie
            animationData={loadingAnimation}
            style={{
              height: 150,
              width: 150,
            }}
            className="absolute hidden sm:block top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        )}

        <div className="relative z-10 pb-8 px-8">
          <div className="flex items-center sm:flex-row flex-col justify-between p-6 sm:hidden  ">
            <div
              className="lg:flex items-center h-10 
					cursor-pointer hidden"
              onClick={() => (window.location.href = "http://topset.app/")}
            >
              <img src={logo} alt="" width={190} height={150} />
            </div>
            <div
              className="relative flex items-center h-10 
					cursor-pointer my-auto lg:hidden"
              onClick={() => (window.location.href = "http://topset.app/")}
            >
              <img src={mobile_logo} alt="" width={160} />
            </div>
            <div className="flex items-center  sm:hidden ">
              <p>
                Powered by{" "}
                <span className="font-bold text-xl text-black">Revolut</span>
              </p>
            </div>
          </div>

          {!invalidLink && !paymentComplete && order && (
            <>
              <div className="container"></div>
              <section className="text-center z-10 container md:mt-12 px-8 mt-4 left-8 right-8 ">
                <h1 className="font-bold md:text-2xl text-2xl leading-10 max-w-3xl mx-auto ">
                  Almost There! Secure Your Spot to Mastery
                </h1>
                <p className="font-medium text-secondary text-lg md:text-lg leading-7 mt-4 max-w-4xl mx-auto ">
                  Complete your checkout and embark on a journey to fluency with
                  our expert tutors
                </p>
              </section>
              <div
                className={`flex gap-5 container  mt-10 relative z-10 sm:flex-row flex-col ${
                  loading && "hidden"
                } `}
              >
                {invoice || invoiceId ? (
                  <InvoicePayment order={order} />
                ) : (
                  <PaymentPlan
                    onBoarding={onBoarding}
                    setInvalidLink={setInvalidLink}
                    order={order}
                    bookLessons={bookLessons}
                    setOrder={setOrder}
                  />
                )}
                <CardInfo
                  handleRevolutPopup={handleRevolutPopup}
                  iframeLoading={iframeLoading}
                  setIframeLoading={setIframeLoading}
                />
              </div>
            </>
          )}
          {paymentComplete && <PaymentMade />}
          {invalidLink && <InvalidPaymentLink dp={learningPackage} />}
        </div>

        <div className="sm:flex items-center fixed bottom-0   hidden  w-full justify-end px-6  pb-2 ">
          {order?.gateway?.name && (
            <p>
              Powered by{" "}
              <span className="font-bold text-xl">{order?.gateway?.name}</span>
            </p>
          )}
        </div>
      </div>

      <ReactModal
        isOpen={stripeModal.open}
        style={{
          overlay: {
            zIndex: 1000,
            backgroundColor: "rgba(0, 0, 0, 0.43)",
          },
          content: {
            border: "none",
            background: "transparent",
            height: "93vh",
            width: "96vw",
            margin: 0,
            padding: 0,
            display: "grid",
            placeItems: "center",
          },
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <div
          style={{
            border: "none",
            background: "white",
            height: 400,
            width: 500,
            borderRadius: "1.5rem",
            padding: 20,
          }}
        >
          <form
            onSubmit={handleSubmit}
            className="grid gap-3 relative"
            style={{
              height: "100%",
              gridTemplateRows: "auto auto 1fr auto",
            }}
          >
            <div
              className="h-[40px] w-[40px] absolute cursor-pointer"
              style={{
                right: 0,
                borderRadius: "50%",
                display: "grid",
                placeItems: "center",
              }}
              onClick={() => setStripeModal((p) => ({ ...p, open: false }))}
            >
              <svg
                fill="none"
                strokeWidth={1.5}
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>

              {/* <XIcon
								onClick={() => setStripeModal(false)}
								className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
							/> */}
            </div>
            <div className="font-bold text-[#191c1f] text-[20px]">
              Topset Education
            </div>

            <p className="text-[#191c1f] text-[14px]">
              <span>{order?.amount?.currency}</span>
              <span>{order?.amount?.value}</span>
            </p>

            {paymentRequest && (
              <div className="grid align-items-center">
                <PaymentRequestButtonElement options={{ paymentRequest }} />
              </div>
            )}

            <div className="grid align-items-center">
              <div className="p-[16px] pt-[20px] rounded-[1rem] bg-[#ebebf2] h-[56px] grid align-items-center">
                <CardElement options={cardElementOptions} />
              </div>
            </div>

            <button
              type="submit"
              disabled={!stripe}
              className="bg-[#4F55F1] h-[52px] text-white rounded-[9999px]"
              style={{
                boxShadow: "rgb(79 85 241 / 0.45) 0 0.1875rem 0.5rem 0",
              }}
            >
              {stripeModal.success ? (
                <>✅</>
              ) : (
                <>
                  <span>Pay {order?.amount?.currency}</span>
                  <span>{order?.amount?.value}</span>
                </>
              )}
            </button>
          </form>
        </div>
      </ReactModal>
    </>
  );
};

export default Revolut;
