export default function TrustpilotBadge() {
  return (
    <div
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="636e6461cdcaa579cfeb9b1b"
      data-style-height="24px"
      data-style-width="100%"
    >
      <a
        href="https://www.trustpilot.com/review/topset.app"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  );
}
