import TrustpilotBadge from "src/components/Shared/TrustpilotBadge";
import { useTrustpilot } from "src/context/TrustpilotContext";

const ChooseAPlan = () => {
  const { isScriptLoaded } = useTrustpilot();

  return (
    <>
      <section className="  text-center z-10 container mt-6 px-8  left-8 right-8 ">
        <h1
          className="font-bold md:text-2xl text-2xl leading-10 max-w-3xl mx-auto "
          style={{
            textShadow: `
                  -1px -1px 0 #fff,  
           1px -1px 0 #fff,
          -1px  1px 0 #fff,
           1px  1px 0 #fff
                  `,
          }}
        >
          Choose a package below to get started.
        </h1>
      </section>
      <div>
        {isScriptLoaded && (
          <div className="mt-2 ">
            <TrustpilotBadge />
          </div>
        )}
      </div>
    </>
  );
};

export default ChooseAPlan;
